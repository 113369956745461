import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";

const IllnessAccidentAssistance = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Auxílio Doença Acidentário"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          {
            page: "/auxilio-doenca-acidentario",
            name: "Auxílio Doença Acidentário",
          },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            O aux. acidentário é muito parecido com o aux. doença comum, todavia
            com algumas diferenças específicas.
          </p>
          <p>
            De início destaca-se que o aux. doença acidentário é devido às
            pessoas que ficaram incapacitadas, de forma temporária, em razão do
            trabalho. Essa incapacidade pode se dá por acidente de trabalho ou
            por alguma doença ocupacional.
          </p>
          <p>
            Neste ponto vale ressaltar que em caso de perda de função ou sua
            redução, o beneficiário também pode ter direito ao auxílio acidente,
            desta forma, leia o texto que trata exclusivamente de “auxílio
            acidente” que também temos aqui na página.
          </p>
          <p>
            Também é diferente o período de carência do Auxílio Doença comum e
            do aux. de natureza acidentária, pois o comum requer que o
            beneficiário tenha uma carência de pelo menos 12 meses, já no de
            natureza trabalhista não é necessário a carência de 12 meses, basta
            que esteja regularmente contribuindo e que comprove a natureza do
            acidente.
          </p>
          <p>
            Destaca-se ainda o fato de o benefício de natureza acidentária
            garantir, ao contribuinte, uma estabilidade no emprego de, pelo
            menos, mais 12 meses após o retorno às suas atividades laborais.
          </p>
          <p>
            Além do já citado, também merece destaque o fato de o beneficiário
            do aux. acidentário ter direito a receber, de seu empregador, o
            FGTS, mesmo durante todo o período em que estiver encostado pelo
            benefício, ou seja, se o beneficiário passar 1 ano recebendo o aux.
            acidentário, o seu empregador deverá continuar realizando as
            contribuições de FGTS mês a mês.
          </p>
          <p>
            Como você pode analisar, os benefícios de aux. doença e aux.
            acidentário, embora parecidos, possuem diversas diferenças, e essas
            diferenças podem trazer grandes prejuízos para o contribuinte,
            inclusive em uma eventual demissão indevida do empregado.
          </p>
          <p>
            Vê-se muitos empregadores demitindo os empregados logo após o
            retorno do aux. ou ainda pior, durante o recebimento do aux. Além
            disto, o tipo de benefício recebido pode trazer grandes implicações
            em caso de aposentadoria por invalidez (incapacidade permanente),
            alterando o valor do benefício.
          </p>
          <p>
            Logo, quando for buscar o recebimento deste benefício busque sempre
            um advogado competente, pois o deferimento do benefício incorreto
            pode te trazer diversos prejuízos futuros.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Auxílio Doença Acidentário"
    description="Assessoria jurídica especializada em Auxílio Doença Acidentário. Oferecemos orientação para garantir seus direitos a benefícios em casos de afastamento por acidente, com suporte confiável e eficiente."
  />
);

export default IllnessAccidentAssistance;
